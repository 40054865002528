import React, { useState, useEffect, useCallback } from 'react';
import { inject, observer } from 'mobx-react';
import debounce from 'lodash.debounce';
import { Form } from 'react-final-form';

import { HELP } from 'shared/engage/engage-help-enums';
import SlimFileUpload from '../../../common/components/form-elements/fileUpload/SlimFileUpload';
import { IMAGE_FORMATS } from '../../../common/components/form-elements/fileUpload/ImageFormatEnums';

import EngageTextInputField from '../../../common/components/form-elements/textInputField/TextInputField';
import ActionBar from '../../../common/components/actionBar/ActionBar';
import EngageMarkdownInputField, {
  MARKDOWN_MID,
} from '../../../common/components/form-elements/markdownInputField/MarkdownInputField';
import { SAMPLE_URL, validateUrl } from '../../../common/UrlUtils';
import SwitchableButtonBar from '../../../common/components/switchableButtonBar/SwitchableButtonBar';
import { getFormFieldClass, getFormFieldTranslationTooltip } from '../../../common/TranslationUtils';
import { Can } from '../../../common/Can';
import { VIEW, PROJECT_DESCRIPTION, PROJECT_WEBSITE } from '../../../common/ability';
import { simpleMemoize, _validateSlugMemoized } from '../../../common/formValidators';

const {
  isSlugValid,
  SLUG_VALIDATION_TYPE: { SLUG_UPDATE },
} = require('../../../shared/engage').ProjectEnums;

function Information(props) {
  const { Store } = props;
  const { project, projectLogo, projectHeader, projectDefault } = Store.project;

  const onSubmit = (values) => {
    const proj = {
      projectName: values.projectName,
      slug: values.slug,
      architects: values.architects ? values.architects : '',
      projectUrl: values.projectUrl ? values.projectUrl : '',
      about: values.about ? values.about : '',
    };
    Store.saveProjectInformation(proj);
  };

  const uploadLogo = (file, progressHandler) => {
    return Store.uploadLogo(file, progressHandler);
  };

  const removeLogo = (event) => {
    event.preventDefault();
    return Store.removeLogo();
  };

  const uploadBackground = (file, progressHandler) => {
    return Store.uploadBackground(file, progressHandler);
  };

  const removeBackground = (event) => {
    event.preventDefault();
    return Store.removeBackground();
  };

  /**
   * Validate slug formally + online uniqueness validation
   * Need to memoize to avoid validating slug on every form update ie. project name
   */
  const validateSlugMemoized = _validateSlugMemoized(Store, SLUG_UPDATE, true);

  return (
    <div className='l-main' data-testid='information-container'>
      <ActionBar
        label='Information'
        hasButton={false}
        helpKey={HELP.PROJECT.EDIT_PROJECT.INFORMATION.PAGE}
        isTextOnlyHelp={true}
      />
      <Form
        onSubmit={onSubmit}
        initialValues={project}
        render={(formProps) => {
          const { handleSubmit, invalid, touched, errors, validating } = formProps;
          return (
            <form className='c-fields l-form' onSubmit={handleSubmit} data-testid='information-form'>
              <EngageTextInputField
                id='input-project-name'
                placeholder='Name'
                label='Project name'
                maxLength='35'
                fieldDescription='Short names work best'
                fieldName='projectName'
                isRequired={true}
                fieldClass={getFormFieldClass(projectDefault?.projectName, null, 'l-form__item--m')}
                tooltipInLabel={getFormFieldTranslationTooltip(projectDefault?.projectName)}
                helpKey={HELP.PROJECT.EDIT_PROJECT.INFORMATION.NAME}
              />
              <EngageTextInputField
                id='input-project-slug'
                placeholder=''
                label='Project slug'
                maxLength='45'
                fieldName='slug'
                isRequired={true}
                fieldClass='l-form__item--m'
                showSlugLabel={true}
                validator={validateSlugMemoized}
                meta={{ touched: touched.slug, error: errors.slug }}
                helpKey={HELP.PROJECT.EDIT_PROJECT.INFORMATION.SLUG}
              />
              <EngageTextInputField
                id='input-project-developers'
                placeholder='Who is working on this?'
                label='Project Leaders'
                maxLength='40'
                fieldDescription=''
                fieldName='architects'
                fieldClass='l-form__item--m'
                helpKey={HELP.PROJECT.EDIT_PROJECT.INFORMATION.DEVELOPERS}
              />
              <Can I={VIEW} a={PROJECT_WEBSITE}>
                <EngageTextInputField
                  id='input-project-url'
                  placeholder={SAMPLE_URL}
                  label='Project Website'
                  fieldDescription='Link to your project website (not required)'
                  fieldName='projectUrl'
                  fieldClass={getFormFieldClass(projectDefault?.projectUrl, null, 'l-form__item--m')}
                  tooltipInLabel={getFormFieldTranslationTooltip(projectDefault?.projectUrl)}
                  validator={(value) => {
                    return validateUrl(value);
                  }}
                />
              </Can>
              <Can I={VIEW} a={PROJECT_DESCRIPTION}>
                <EngageMarkdownInputField
                  id='input-project-description'
                  placeholder='Make it short, sweet and under 1100 characters.'
                  label='Project description'
                  maxLength='1100'
                  fieldDescription=''
                  fieldName='about'
                  fieldClass={getFormFieldClass(projectDefault?.about)}
                  tooltipInLabel={getFormFieldTranslationTooltip(projectDefault?.about)}
                  editorConfig={MARKDOWN_MID}
                />
              </Can>
              <SlimFileUpload
                imageFormat={IMAGE_FORMATS.AVATAR}
                imageId={projectLogo}
                id='project-logo'
                handleUpload={uploadLogo}
                label='Project logo'
                tip='Image should be square and at least 200x200px.'
                removeImage={removeLogo}
                enableLibrarySearch={false}
              />
              <SlimFileUpload
                imageFormat={IMAGE_FORMATS.BACKGROUND}
                imageId={projectHeader}
                id='project-bg'
                handleUpload={uploadBackground}
                label='Project background'
                tip='Wide landscape images without any text work best as it might get cropped. Image should be at least 1600x400.'
                removeImage={removeBackground}
              />
              <SwitchableButtonBar updateAction={handleSubmit} updateEnabled={!(invalid || validating)} />
            </form>
          );
        }}
      />
    </div>
  );
}

export default inject((root) => ({ Store: root.RootStore.projectInformationStore }))(observer(Information));
