import { AbilityBuilder, Ability } from '@casl/ability';
import { RoleEnums, ServiceEnums, PermissionEnums } from '../shared/engage';
import {
  OVERVIEW,
  EDIT_PROJECT,
  REPORTS,
  PUBLISHING,
  GEOLOCATION,
  INFORMATION,
  TIMELINE,
  LANGUAGES,
  IMAGE_GALLERY,
  TEAM,
  INFLUENCED_DECISIONS,
  CHARITIES,
  PANELS,
  POLLS,
  SEGMENTATION,
  DEMOGRAPHICS,
  CUSTOMIZE,
  CONTENT,
  ACTIONS,
  BLOCKS,
  PERSONAL_DATA,
  FAQ,
  NEWS,
  REPORTS_DASHBOARD,
  REPORTS_POLLS,
  REPORTS_FEEDBACK,
  REPORTS_DEMOGRAPHICS,
  REPORTS_GENERATE,
  SETTINGS,
  EMBED,
  TOOLS,
  CONFIGURATION,
} from './constants/RouteConstants';

const EngagePanelLocationType = require('../shared/engage/engage-panel-enums')().EngagePanelLocationType;

// Actions
export const VIEW = 'view';
export const EDIT = 'edit';
export const SET = 'set';

// Action targets
export const USERS = 'USERS';
export const HOMEPAGE_CONTENT = 'HOMEPAGE_CONTENT';
export const POLLS_CONTENT = 'POLLS_CONTENT';
export const FEEDBACK_QUESTION_CONTENT = 'FEEDBACK_QUESTION_CONTENT';
export const CHARITIES_CONTENT = 'CHARITIES_CONTENT';
export const MORE_CONTENT = 'MORE_CONTENT';

export const PANEL_LOCATION_ALL = 'PANEL_LOCATION_ALL';
export const PANEL_LOCATION_HOME = 'PANEL_LOCATION_HOME';
export const PANEL_LOCATION_TIMELINE = 'PANEL_LOCATION_TIMELINE';
export const PANEL_LOCATION_NEWS_AND_MEDIA = 'PANEL_LOCATION_NEWS_AND_MEDIA';
export const PANEL_LOCATION_POLLS = 'PANEL_LOCATION_POLLS';

export const EMBED_START_HOME_SCREEN = 'EMBED_START_HOME_SCREEN';
export const EMBED_START_POLLS_SCREEN = 'EMBED_START_POLLS_SCREEN';

export const PROJECT_WEBSITE = 'PROJECT_WEBSITE';
export const PROJECT_DESCRIPTION = 'PROJECT_DESCRIPTION';

export const DUPLICATE_SURVEY = 'DUPLICATE_SURVEY';

export const superClient = {
  subscriptionType: ServiceEnums().serviceServices.basicEngageService,
};

const ability = new Ability([]);

function setFullVisibility(can) {
  can(VIEW, OVERVIEW);
  can(VIEW, EDIT_PROJECT);
  can(VIEW, REPORTS);
  can(VIEW, PUBLISHING);
  can(VIEW, GEOLOCATION);
  can(VIEW, TIMELINE);
  can(VIEW, INFORMATION);
  can(VIEW, LANGUAGES);
  can(VIEW, IMAGE_GALLERY);
  can(VIEW, TEAM);
  can(VIEW, INFLUENCED_DECISIONS);
  can(VIEW, CHARITIES);
  can(VIEW, POLLS);
  can(VIEW, SEGMENTATION);
  can(VIEW, DEMOGRAPHICS);
  can(VIEW, CUSTOMIZE);
  can(VIEW, PANELS);
  can(VIEW, CONTENT);
  can(VIEW, ACTIONS);
  can(VIEW, BLOCKS);
  can(VIEW, FAQ);
  can(VIEW, NEWS);
  can(VIEW, REPORTS_DASHBOARD);
  can(VIEW, REPORTS_POLLS);
  can(VIEW, REPORTS_DEMOGRAPHICS);
  can(VIEW, REPORTS_FEEDBACK);
  can(VIEW, REPORTS_GENERATE);
  can(VIEW, SETTINGS);
  can(VIEW, CONFIGURATION);
  can(VIEW, EMBED);
  can(VIEW, PROJECT_WEBSITE);
  can(VIEW, PROJECT_DESCRIPTION);

  can(EDIT, HOMEPAGE_CONTENT);
  can(EDIT, POLLS_CONTENT);
  can(EDIT, FEEDBACK_QUESTION_CONTENT);
  can(EDIT, CHARITIES_CONTENT);
  can(EDIT, MORE_CONTENT);

  can(SET, PANEL_LOCATION_ALL);
  can(SET, PANEL_LOCATION_HOME);
  can(SET, PANEL_LOCATION_TIMELINE);
  can(SET, PANEL_LOCATION_NEWS_AND_MEDIA);
  can(SET, PANEL_LOCATION_POLLS);

  can(SET, EMBED_START_HOME_SCREEN);
  can(SET, EMBED_START_POLLS_SCREEN);
}

function setPollsOnlyVisibility(can) {
  can(VIEW, EDIT_PROJECT);
  can(VIEW, REPORTS);
  can(VIEW, SETTINGS);
  can(VIEW, INFORMATION);
  can(VIEW, LANGUAGES);
  can(VIEW, CHARITIES);
  can(VIEW, POLLS);
  can(VIEW, SEGMENTATION);
  can(VIEW, DEMOGRAPHICS);
  can(VIEW, CUSTOMIZE);
  can(VIEW, PANELS);
  can(VIEW, CONTENT);
  can(VIEW, ACTIONS);
  can(VIEW, REPORTS_DASHBOARD);
  can(VIEW, REPORTS_POLLS);
  can(VIEW, REPORTS_FEEDBACK);
  can(VIEW, REPORTS_DEMOGRAPHICS);
  can(VIEW, REPORTS_GENERATE);
  can(VIEW, PUBLISHING);
  can(VIEW, CONFIGURATION);
  can(VIEW, SETTINGS);
  can(VIEW, EMBED);

  can(EDIT, POLLS_CONTENT);
  can(EDIT, FEEDBACK_QUESTION_CONTENT);
  can(EDIT, CHARITIES_CONTENT);

  can(SET, PANEL_LOCATION_POLLS);

  can(SET, EMBED_START_POLLS_SCREEN);
}

function isToolsPageAvailable(userRole) {
  const roleIds = RoleEnums().roleIDs;
  return [
    roleIds.SUPER_ADMIN,
    roleIds.ADMINISTRATOR,
    roleIds.ACCOUNT_MANAGER,
    roleIds.ACCOUNT_ASISTANT,
    roleIds.ACCOUNT_VIEWER,
    roleIds.CLIENT_MASTER,
  ].includes(userRole);
}

function isDuplicateSurveyAvailable(userRole) {
  const roleIds = RoleEnums().roleIDs;
  return [
    roleIds.SUPER_ADMIN,
    roleIds.ADMINISTRATOR,
    roleIds.ACCOUNT_MANAGER,
    roleIds.ACCOUNT_VIEWER,
    roleIds.CLIENT_MASTER,
  ].includes(userRole);
}

/**
 * Allows user to handle users (View and Edit)
 * ref https://github.com/sdesregistry/IH-Engage/issues/2341
 * All team users (having CAN_CREATE_CLIENT_USERS) permissions are allowed to VIEW and EDIT users
 * Client users can VIEW if they are either Master(Client) or User (Client) (having CLIENT_CAN_CREATE_CLIENT_USERS)
 * Client users can EDIT if they are Master(Client) (having CLIENT_CAN_CREATE_BRANCH_CLIENT_USERS)
 * The mess up is that the permissions are not consistent with the roles and User (Client) has CLIENT_BRANCH_MANAGER role
 * that is roles were flattened by one level.
 * Alternative was to add new permission for viewing user (but not edit)
 * NOTE: The API would still allow User (Client) to create a user if someone manages to bypass the UI
 */
function allowHandleUsers(can, permissions = []) {
  const allPermissions = PermissionEnums().MANAGE_ENGAGE_USER_PERMISSIONS;

  // Enable VIEW Users
  const validPermissionsToView = [
    allPermissions.CAN_CREATE_CLIENT_USERS,
    allPermissions.CLIENT_CAN_CREATE_CLIENT_USERS,
    allPermissions.CLIENT_CAN_CREATE_BRANCH_CLIENT_USERS,
  ];
  const canViewUser = permissions?.some((permission) => validPermissionsToView.includes(permission));
  // console.log('canViewUser', canViewUser);
  if (canViewUser) {
    can(VIEW, USERS);
  }

  // Enable EDIT Users
  const validPermissionsToEdit = [
    allPermissions.CAN_CREATE_CLIENT_USERS,
    allPermissions.CLIENT_CAN_CREATE_BRANCH_CLIENT_USERS,
  ];
  const canCreateUser = permissions?.some((permission) => validPermissionsToEdit.includes(permission));
  // console.log('canCreateUser', canCreateUser);
  if (canCreateUser) {
    can(EDIT, USERS);
  }
}

export function updateAbility(
  updatedAbility,
  userRoles = [],
  isPollsOnlyProject = false,
  isPersonalDataAcquisitionEnabled = false,
) {
  const { can, rules } = new AbilityBuilder(Ability);
  const userRole = userRoles[0]?._id;
  const userPermissions = userRoles[0]?.permissions;

  if (isPollsOnlyProject) {
    setPollsOnlyVisibility(can, isPersonalDataAcquisitionEnabled);
  } else {
    setFullVisibility(can, isPersonalDataAcquisitionEnabled);
  }

  if (isPersonalDataAcquisitionEnabled) {
    can(VIEW, PERSONAL_DATA);
  }

  const toolsPageAvailable = isToolsPageAvailable(userRole);
  if (toolsPageAvailable) {
    can(VIEW, TOOLS);
  }

  const duplicateSurveyAvailable = isDuplicateSurveyAvailable(userRole);
  if (duplicateSurveyAvailable) {
    can(VIEW, DUPLICATE_SURVEY);
  }

  allowHandleUsers(can, userPermissions);

  updatedAbility.update(rules);
}

export default ability;
