export const VERSION = '4.70.3';
export const GMV_COOKIE = 'GMV_COOKIE';
export const ADMIN_API_ROOT = '/gmv-admin-api/v1';
export const ADMIN_API_ROOT_V2 = '/gmv-admin-api/v2';
export const BACKEND_API_ROOT = '/gmv-backend-api/v1';
export const BACKEND_API_ROOT_V2 = '/gmv-backend-api/v2';
export const USER_API_ROOT = '/gmv/user/v1';

// Shared constant to signal V2 API usage
export const USE_API_V2 = 'USE_API_V2';

// Obsolete
// B-ID API root
export const BUILT_ID_API_ROOT = '/api/v1';

// Related to Issue#2246 [https://github.com/sdesregistry/IH-Engage/issues/2246]
// Re-enabled adding annotate image question as per Issue#2306 [https://github.com/sdesregistry/IH-Engage/issues/2306]
export const DISABLE_ANNOTATE_IMAGE_QUESTION = false;
