import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { useAbility } from '@casl/react';

import { OVERVIEW, EDIT_PROJECT, REPORTS, SETTINGS, PUBLISHING } from '../../common/constants/RouteConstants';
import ProjectEditContainer from './ProjectEditContainer';
import ReportingContainer from './ReportingContainer';

import Overview from './OverviewContainer';
import ImageLibraryModal from '../../common/components/imageLibraryModal/ImageLibraryModal';
import { updateAbility, VIEW } from '../../common/ability';
import { AbilityContext, Can } from '../../common/Can';
import SettingsContainer from './SettingsContainer';

function Project(props) {
  const { match, ProjectStore, AuthStore, ImageLibraryStore } = props;

  const ability = useAbility(AbilityContext);

  useEffect(() => {
    ProjectStore.getById(match.params.id).then((project) => {
      // console.log('PROJECT', ProjectStore.project._id, ProjectStore.project.profileId);

      // update the UI according to client profile type (hide items if POLLS_ONLY)
      updateAbility(ability, AuthStore.me.roles, project.isPollsOnlyProject, project.isPersonalDataAcquisitionEnabled);

      // !!!####
      // Sax
      // NOT SURE THIS IS SMART???!!!!
      // And I'm sure it's pretty ugly
      // Alternative would be to load the project on App init but then I would need to
      // parse the projectId from URL and repeat the project loading logic
      //
      // I have to update clientId in Auth store in case user arrives directly with project URL
      // In this case (for staff users) I don't have a client selected as the first step
      // Usually the case happens for staff users working on a project and hitting F5 (refresh)
      //
      // ### Also, I have to wait for both project and /me to be loaded
      // happens often that /me arrives AFTER /project and then removes ClientId selection
      if (ProjectStore.project && AuthStore.me._id) {
        // console.log('Setting client ID from project:', ProjectStore.project._profileId)
        AuthStore.setClientId(ProjectStore.project._profileId);
      }

      ProjectStore.registerAsVisited(match.params.id);
    });
  }, [match.params.id, ProjectStore, AuthStore, AuthStore.me._id, ProjectStore.project._id, ability]);

  return (
    <div className='l-body'>
      <div className='l-container'>
        <Switch>
          <Route
            path={`${match.path}/${OVERVIEW}`}
            render={(componentProps) => (
              <Can I={VIEW} a={OVERVIEW}>
                {() => <Overview {...componentProps} />}
              </Can>
            )}
          />
          <Route
            path={`${match.path}/${EDIT_PROJECT}`}
            render={(componentProps) => (
              <Can I={VIEW} a={EDIT_PROJECT}>
                {() => <ProjectEditContainer {...componentProps} />}
              </Can>
            )}
          />
          <Route
            path={`${match.path}/${REPORTS}`}
            render={(componentProps) => (
              <Can I={VIEW} a={REPORTS}>
                {() => <ReportingContainer {...componentProps} />}
              </Can>
            )}
          />
          <Route
            path={`${match.path}/${SETTINGS}`}
            render={(componentProps) => (
              <Can I={VIEW} a={SETTINGS}>
                {() => <SettingsContainer {...componentProps} />}
              </Can>
            )}
          />
        </Switch>
      </div>
      {ImageLibraryStore.modalOpen && <ImageLibraryModal closeModal={() => (ImageLibraryStore.modalOpen = false)} />}
    </div>
  );
}

export default inject((root) => ({
  ProjectStore: root.RootStore.projectStore,
  AuthStore: root.RootStore.authStore,
  ImageLibraryStore: root.RootStore.imageLibraryStore,
}))(observer(Project));
