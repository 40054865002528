import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import { useAbility } from '@casl/react';
import { updateAbility } from '../../common/ability';
import { AbilityContext, Can } from '../../common/Can';

import history from '../../common/history';

import { USERS, CREATE_PROJECT, EDIT_USERS, STAFF, CLIENTS } from '../../common/constants/RouteConstants';
import ProfileMasthead from '../../common/masthead/ProfileMasthead';
import Users from './users/Users';
import Projects from './projects/Projects';
import CreateProject from './createProject/CreateProject';
import EditUser from './users/EditUser';

function Profile(props) {
  const { match, AuthStore, HelpStore } = props;
  // console.log(match);
  const ability = useAbility(AbilityContext);

  /**
   * Redirect back to clientList if client Id is not selected
   * This is probably the case with F5 (refresh)
   */
  useEffect(() => {
    updateAbility(ability, AuthStore.me.roles);

    if (!AuthStore.clientId) {
      history.push(`/${STAFF}/${CLIENTS}`);
    }
  }, [AuthStore.clientId, AuthStore.me.roles, ability]);

  return (
    <div className='l-body'>
      <div className='l-container'>
        <div className={`l-page ${HelpStore.isContextualHelpActive ? 'l-page--show-help' : ''}`}>
          <div className='l-masthead'>
            <Route path={`${match.path}/:route`} component={ProfileMasthead} />
          </div>
          <div className='l-main'>
            <Switch>
              <Route path={`${match.path}/${USERS}/${EDIT_USERS}/:userId/`} component={EditUser} />
              <Route path={`${match.path}/${USERS}/${EDIT_USERS}`} component={EditUser} />
              <Route path={`${match.path}/${USERS}`} component={Users} />
              <Route path={`${match.path}/${CREATE_PROJECT}`} component={CreateProject} />
              <Route component={Projects} />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}

export default inject((root) => ({ AuthStore: root.RootStore.authStore, HelpStore: root.RootStore.helpStore }))(
  observer(Profile),
);
