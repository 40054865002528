import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import ability, { EDIT, USERS } from '../../../common/ability';
import ActionBar from '../../../common/components/actionBar/ActionBar';
import UserGridRow from './UserGridRow';
import history from '../../../common/history';
import { EDIT_USERS } from '../../../common/constants/RouteConstants';

function Users(props) {
  const { Store, AuthStore, match } = props;

  const users = Store.users;
  const clientId = AuthStore.clientId;

  useEffect(() => {
    if (clientId) Store.getAll(clientId);
  }, [Store, clientId]);

  function addNew() {
    history.push(`${match.url}/${EDIT_USERS}`);
  }

  const canEditUser = ability.can(EDIT, USERS);

  return (
    <div className='l-main'>
      <ActionBar addNew={addNew} label='Users' hasButton={canEditUser} buttonLabel='Add' />
      <div className='c-table'>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th />
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <UserGridRow key={user._id} user={user} baseUrl={match.url} clientId={clientId} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

Users.propTypes = {
  Store: PropTypes.object.isRequired,
  AuthStore: PropTypes.object.isRequired,
};

export default inject((root) => ({ Store: root.RootStore.usersStore, AuthStore: root.RootStore.authStore }))(
  observer(Users),
);
